<template>
	<section class="content-main">
		<el-form :model="form" label-width="140px">
			<el-form-item label="店铺彩种">
				<el-checkbox-group v-model="form.type">
					<el-checkbox label="竞彩" name="type"></el-checkbox>
					<el-checkbox label="足彩" name="type"></el-checkbox>
					<el-checkbox label="体彩数字" name="type"></el-checkbox>
					<el-checkbox label="体彩高频" name="type"></el-checkbox>
					<el-checkbox label="福彩数字" name="type"></el-checkbox>
					<el-checkbox label="福彩高频" name="type"></el-checkbox>
					<el-checkbox label="北单" name="type"></el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="店铺支付权限">
				<el-radio-group v-model="form.resource">
					<el-radio label="开启"></el-radio>
					<el-radio label="系统关闭"></el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="店铺支付费率">
				<p>{{form.true_name}}</p>
			</el-form-item>
			<el-form-item label="">
				<el-button type="primary" @click.native="saveSubmit" icon="el-icon-circle-check">保存修改</el-button>
			</el-form-item>
		</el-form>

	</section>
</template>

<script>
	import {
		updateStoreInfo
	} from '../../api/api';
	export default {
		data() {
			return {
				form: {}
			}
		},
		methods: {
			//取消返回
			goBack() {
				this.$router.go(-1);
			},

			
			//编辑保存
			saveSubmit: function() {
				//判断是否有不存在的数组ID
				let a = this.roleOption.map(i => i.id),
					b = this.form.role_id
				for(var i of b) {
					if(a.indexOf(i) < 0) {
						b.splice(b.indexOf(i), 1)
					}
				}
			},
		},
		mounted() {
			this.getInfo()
		}
	}
</script>
<style scoped>
	.interval {
		width: 100%;
		height: 12px;
		overflow: hidden;
		border-bottom: 1px solid #dddddd;
		margin-bottom: 12px
	}
</style>